.svg-topo {
    width : 25px;
    height: 25px;
    fill  : #fff;
}

.navbar-light .navbar-nav .nav-link.nav-link-custom {
    background   : #E90804;
    color        : #fff;
    border-radius: 5px;
    transition   : 250ms all ease;
}

.navbar-light .navbar-nav .nav-link.nav-link-custom:hover {
    background                : #bd0502;
    transition                : 250ms all ease;
    transition-timing-function: cubic-bezier(1, .05, .29, .77);
}

.nav-item {
    position: relative;
}

.nav-item:not(:last-child)::after {
    position  : absolute;
    content   : '';
    height    : 40px;
    width     : 1px;
    top       : 0;
    right     : 0;
    background: #ddd;
}

// SUBLINHADO MENU
.barra-bottom-direita {
    position        : absolute;
    display         : inline-block;
    left            : 50%;
    top             : 90%;
    margin          : 0 auto;
    height          : 1px;
    background-color: #b9b3b3;
    width           : 0%;
    transition      : width .3s;
}

.barra-bottom-esquerda {
    position        : absolute;
    display         : inline-block;
    right           : 50%;
    top             : 90%;
    margin          : 0 auto;
    height          : 1px;
    background-color: #b9b3b3;
    width           : 0%;
    transition      : width .3s;
}

.dropdown-menu {
    margin: 0;
}

.dropdown:hover>.dropdown-menu {
    display: block;
}

.dropdown-menu-right-custom {
    right: 235px;
    top: -0.5rem;
}

// .dropdown-menu-right-custom2 {
//     right: 235px;
//     top: -0.5rem;
// }

.dropdown-item {
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.barra-bottom {
    color: #000;
}

.barra-bottom:hover>.barra-bottom-direita {
    width: 30%;
}

.barra-bottom:hover>.barra-bottom-esquerda {
    width: 30%;
}

// FIM SUBLINHADO MENU

.link-bread {
    color: #e90804;
}

.link-bread:hover {
    text-decoration: none;
    color          : #bd0502;

}

.bread-custom {
    background: linear-gradient(45deg, #e9ecef, #ffffff);
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .nav-item:not(:last-child)::after {
        display: none;
    }

    .navbar-light .navbar-nav .nav-link.nav-link-custom {
        background   : transparent;
        color        : inherit;
        border-radius: 5px;
        transition   : 250ms all ease;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .nav-item:not(:last-child)::after {
        display: none;
    }

    .navbar-light .navbar-nav .nav-link.nav-link-custom {
        background   : transparent;
        color        : inherit;
        border-radius: 5px;
        transition   : 250ms all ease;
    }
}

@media (max-width: 575.98px) {
    .nav-item:not(:last-child)::after {
        display: none;
    }

    .navbar-light .navbar-nav .nav-link.nav-link-custom {
        background   : transparent;
        color        : inherit;
        border-radius: 5px;
        transition   : 250ms all ease;
    }
}